<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="testudio_financiero"
      :rules="formRules.idtestudio_financiero"
    >
      <b10-autocomplete
        v-model="form.idtestudio_financiero"
        :items="tipoEstudioFinanciero"
        item-value="idtestudio_financiero"
        item-text="descripcion"
        label="Tipo de estudio financiero"
        clearable
        :error-messages="errors"
        @change="changeTipoEstudioFinanciero"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="fsolicitud"
      :rules="formRules.fsolicitud"
    >
      <b10-date-picker
        v-model="form.fsolicitud"
        title="Fecha de solicitud"
        :error-messages="errors"
        @change="changeFechaSolicitud"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="resultado"
      :rules="formRules.resultado"
    >
      <v-radio-group
        v-model="form.resultado"
        :error-messages="errors"
      >
        <template #label>
          <div>Resultado</div>
        </template>
        <v-radio
          :label="ESTUDIO_FINANCIERO.descripciones[ESTUDIO_FINANCIERO.resultado.favorable]"
          :value="ESTUDIO_FINANCIERO.resultado.favorable"
        />
        <v-radio
          :label="ESTUDIO_FINANCIERO.descripciones[ESTUDIO_FINANCIERO.resultado.noFavorable]"
          :value="ESTUDIO_FINANCIERO.resultado.noFavorable"
        />
      </v-radio-group>
    </ValidationProvider>
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './EstudioFinancieroFormData'
import { addDays, addMonths, currentDate, toDate } from '@/utils/date'
import _ from '@/utils/lodash'
import { ESTUDIO_FINANCIERO } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    usuarioIdempleado: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      form: {
        idempleado: this.usuarioIdempleado,
        idtestudio_financiero: null,
        fsolicitud: currentDate(),
        fvalidez: currentDate(),
        importe_limite: null,
        observaciones: null,
        resultado: null,
      },
      formRules: {
        idtestudio_financiero: { required: true },
        fsolicitud: { required: true },
        fvalidez: { required: true },
        resultado: { required: true },
      },
      tipoEstudioFinanciero: [],
      ESTUDIO_FINANCIERO,
    }
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.tipoEstudioFinanciero = resp.data.selectTestudioFinanciero.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
    },
    calcularImporteLimiteYFechaValidez(idtestudioFinanciero) {
      const tipoEstudioFinancieroSelected = _.find(this.tipoEstudioFinanciero, { idtestudio_financiero: idtestudioFinanciero })
      if (tipoEstudioFinancieroSelected && this.form.fsolicitud) {
        this.$set(this.form, 'importe_limite', tipoEstudioFinancieroSelected.importe_limite)
        this.$set(this.form, 'fvalidez', addMonths(
          addDays(
            toDate(this.form.fsolicitud),
            tipoEstudioFinancieroSelected.dias_validez
          ),
          tipoEstudioFinancieroSelected.meses_validez
        ))
      } else {
        this.$set(this.form, 'fvalidez', null)
      }
    },
    changeFechaSolicitud (value) {
      if (value) {
        this.calcularImporteLimiteYFechaValidez(this.form.idtestudio_financiero)
      }
    },
    changeTipoEstudioFinanciero (value) {
      this.calcularImporteLimiteYFechaValidez(value)
    },
  },
}
</script>
