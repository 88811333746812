import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectTestudioFinanciero', method: 'testudioFinanciero.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idestudio_financiero', id)
    const resp = await Vue.$api.call('estudioFinanciero.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
