export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'estudioFinanciero.update',
      {
        values: {
          idestudio_financiero: id,
          idtestudio_financiero: formData.idtestudio_financiero,
          fsolicitud: formData.fsolicitud,
          fvalidez: formData.fvalidez,
          importe_limite: formData.importe_limite,
          observaciones: formData.observaciones,
          resultado: formData.resultado,
        },
      },
    )
  },
}
